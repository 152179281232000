import React from 'react';

import HeaderTopBar from '../HeaderTopBar';
import Breadcrumb from '../Elements/Breadcrumb';
import RefinancingAreas from '../Elements/RefinancingAreas';
import Footer from '../Elements/Footer'; 
import CompareRefinancing from '../Elements/CompareRefinancing';
import RequestQuote from '../Elements/RequestQuote';
import ClientSlider from '../Elements/ClientSlider';


export const ApplyPage = () => (
    <div className="body_wrapper">
        <HeaderTopBar Logo="logo.png" />
        <RequestQuote />
        <Footer CPLeft="© Copyright Wonder Digital Media AS" CPRight="Alle rettigheter forbeholdt." />
    </div>
)